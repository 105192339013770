import './nameTile.css';
import './nameTileMobile.css';
import './nameTileTablet.css';

import {randomIntFromInterval, useWindowDimensions} from "../../../func";
import {useEffect, useRef, useState} from "react";

const NameTile = (props) => {
  let {width} = props;

  let beer = props.beer
  let colours = ['#08229e', '#44bc19', '#d4282d', '#d761b8']
  let [nameColour, setNameColour] = useState('')
  let [infoColour, setInfoColour] = useState('')

  let nameRef = useRef(null);
  let nameContainerRef = useRef(null);
  let [fontSize, setFontSize] = useState('1.4vmin')

  let style = beer.style
  if (style.includes('-')) {
    style = style.split('-')[0]
  } else if (style.includes('/')) {
    style = style.split('/')[0]
  }

  const checkOverflow = () => {
    let overflow = false;
    if (nameContainerRef.current) {
      const containerRect = nameContainerRef.current.getBoundingClientRect();
      containerRect.width = containerRect.width - 20; // Adjust for margin

      // Get the text content and split it into words
      const textContent = nameContainerRef.current.firstChild.textContent; // Assuming text is the first child
      const words = textContent.split(' ');

      // Get the computed styles of the text content
      const computedStyle = window.getComputedStyle(nameContainerRef.current.firstChild);

      // Create a temporary span to measure each word's width
      const measureSpan = document.createElement('span');
      measureSpan.style.font = computedStyle.font; // Apply the same font styles
      document.body.appendChild(measureSpan);

      for (const word of words) {
        measureSpan.textContent = word;
        const wordWidth = measureSpan.getBoundingClientRect().width;

        if (wordWidth > containerRect.width) {
          overflow = true;
          break; // No need to check further if one word overflows
        }
      }

      document.body.removeChild(measureSpan); // Clean up
    }

    return overflow;
  }

  useEffect(() => {
    if (nameColour === '') {
      setNameColour(colours[randomIntFromInterval(0, 3)])
    }
    if (infoColour === '') {
      setInfoColour(colours[randomIntFromInterval(0, 3)])
    }
  }, []);

  useEffect(() => {
    let name = nameRef.current;
    if (name !== null) {
      let divHeight = name.offsetHeight;
      let lineHeight = parseInt(getComputedStyle(name).lineHeight);
      let lines = divHeight / lineHeight;
      console.log(name, divHeight, lineHeight, lines, width);

      if (lines > 5) {
        if (width >= 1200) {
          setFontSize('0.85vmin')
        } else if (width < 1200 && width > 640) {
          setFontSize('1.0vmin')
        } else {
          setFontSize('1.6vmin')
        }
      } if (lines > 4) {
        if (width >= 1200) {
          setFontSize('1.1vmin')
        } else if (width < 1200 && width > 640) {
          setFontSize('1.2vw')
        } else {
          setFontSize('1.8vw')
        }
      } else {
        if (checkOverflow()) {
          if (width >= 1200) {
            setFontSize('1.1vmin')
          } else if (width < 1200 && width > 640) {
            setFontSize('1.2vw')
          } else {
            setFontSize('1.8vw')
          }
        } else {
          if (width >= 1200) {
            setFontSize('1.4vmin')
          } else if (width < 1200 && width > 640) {
            setFontSize('1.6vw')
          } else {
            setFontSize('2.2vw')
          }
        }
      }
    }
  }, [beer, nameRef.current])

  return (
    <div className={'grid-item'}>
      <div ref={nameContainerRef} style={{color: nameColour}} className={'beerGridName'}>
        {beer.name !== '' ? <div ref={nameRef} style={{fontSize: fontSize}} className={'beerName'}>
          {beer.name}
        </div> : <span className={'loadingTextPlaceholder'}></span>}

        <div className={'beerNameDivider'}></div>

        {beer.brewery !== '' ? <div className={'beerBrewery'}>
          {beer.brewery}
        </div> : <span className={'loadingTextPlaceholder'}></span>}

        <div className={'beerNameDivider'}></div>

        <div
          className={'beerInfo'}
          style={{
            color: infoColour,
            width: beer.abv !== '' ? `calc(100% - 20px)` : `100%`
          }}>
          {beer.abv !== '' ?
            <>
              <div className={'beerAbv'}>
                {beer.abv}%
              </div>
              <div className={'beerStyle'}>
                {style}
              </div>
            </>
            :
            <>
              <span className={'loadingTextPlaceholder'}></span>
              <span className={'loadingTextPlaceholder'}></span>
            </>}
        </div>
      </div>
    </div>
  )
}

export default NameTile;