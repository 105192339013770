export const findXTablesOfX = (tables, teamNumber, tableNumber) => {
  const availableTables = tables.filter((t) => t.capacity === teamNumber && t.currentSize === 0);
  return availableTables.length >= tableNumber ? availableTables.slice(0, tableNumber) : [];
};

export const checkAllTeamsContained = (bookings, tables) => {
  const allTableTeamIds = tables.flatMap(table => table.teams.map(team => team.teamId));
  return bookings.filter(team => !allTableTeamIds.includes(team.teamId));
};

function getExactTable(currentTables, team) {
  return currentTables.find((table) => (table.capacity === team.size && table.currentSize === 0));
}

function getPlusXTable(currentTables, team, foldingChairs, extra) {
  return currentTables.find((table) => ((table.capacity + extra === team.size && ((foldingChairs >= extra && table.capacity !== 2) || (foldingChairs > extra && table.capacity === 2)) && table.capacity !== 3)) && table.currentSize === 0);
}

function getFitTable(currentTables, team, foldingChairs) {
  return currentTables.filter((table) => (
      table.capacity >= team.size || (table.capacity + 1 >= team.size && foldingChairs > 0 && table.capacity !== 3)) &&
      table.currentSize === 0 && (team.size >= 4 || team.size <= 2));
}

export function assignTables(tables, modifiedTables, teams) {
  let cannotBeSeated = [];
  let emptyTables = [];
  let printBookings = [];
  let totalPeople = 0;
  let foldingChairs = 5;

  let currentTables = tables;
  if (modifiedTables !== null) {
    currentTables = JSON.parse(JSON.stringify(modifiedTables));
  }

  teams.sort((a, b) => a.id - b.id); // First team that booked first (ID 1 first)
  currentTables.sort((b, a) => b.capacity - a.capacity); // Smallest first

  function assignTable(table, team, printBookings, teamSize, shouldPushPrint = true) {
    table.currentSize += parseInt(teamSize);
    table.teams.push(team);
    totalPeople += teamSize;
    if (shouldPushPrint) {
      printBookings.push(team);
    }
    if (teamSize > table.capacity) {
      foldingChairs -= teamSize - table.capacity;
    }

    return true;
  }

  function resetTable(table, printBookings) {
    totalPeople -= table.currentSize;
    // printBookings = printBookings.filter((booking) => booking !== table.teams[0])
    if (table.currentSize > table.capacity) {
      foldingChairs += table.currentSize - table.capacity;
    }
    table.currentSize = 0;
    table.teams = [];
  }

  for (const team of teams) {
    if (Array.isArray(team.size)) {
      team.size = team.size.reduce((a, b) => a + b, 0);
    }

    let exactTable = getExactTable(currentTables, team);

    // let plusOneTable = getPlusXTable(currentTables, team, foldingChairs, 1);
    // console.log(plusOneTable, team.name)
    // if (plusOneTable) {
    //   exactTable = plusOneTable;
    // } else if (!exactTable && !plusOneTable) {
    //   exactTable = getPlusXTable(currentTables, team, foldingChairs, 2);
    // }
    if (!exactTable) {
      exactTable = getPlusXTable(currentTables, team, foldingChairs, 1);
    }

    // Is there a table that fits the exact team size
    if (exactTable) {
      assignTable(exactTable, team, printBookings, team.size);
    } else {
      let fitTable = getFitTable(currentTables, team, foldingChairs);

      // Is there a table that has a greater capacity? (e.g. fit a 6 on an 8)
      // Ensure that if there are tables of 2 left, and the team size is <= 4, then do that instead of seating on table of 8
      if (fitTable.length > 0 &&
          (
              (team.size >= 4 && (
                  findXTablesOfX(currentTables, 2, Math.ceil(team.size / 2)).length < Math.ceil(team.size / 2) ||
                  fitTable.length >= 2)
              )
              ||
              (team.size <= 3 && (
                  findXTablesOfX(currentTables, 3, 1).length >= 1 ||
                  currentTables.filter((table) => table.currentSize === 0).length === 1)
              )
          )
      ) {
        // If current team size is 2, and only one table of 3 is left, make sure they are seated on ID 8 not 9
        fitTable = fitTable.sort((a, b) => a.capacity - b.capacity)[0]; // Use smallest table possible
        let table8 = currentTables.find((table) => table.id === 8);
        if (fitTable.id === 9 && team.size === 2 && table8.currentSize === 3) {
          assignTable(fitTable, table8.teams[0], printBookings, 3, false);
          resetTable(table8, printBookings);
          assignTable(table8, team, printBookings, team.size);
        } else {
          assignTable(fitTable, team, printBookings, team.size);
        }
      } else {
        // If team is size is (4, 5, 6, 8), split across tables of 2
        if ((team.size > 3 && team.size <= 8) || (team.size === 9 && foldingChairs > 0)) {
          let teamSize = foldingChairs > 0 && team.size % 2 !== 0 ? team.size - 1 : team.size;
          let tablesOfTwo = findXTablesOfX(currentTables, 2, Math.ceil(teamSize / 2));

          if (tablesOfTwo.length > 0) {
            teamSize = team.size;
            for (let subTable of tablesOfTwo) {
              assignTable(subTable, team, printBookings,
                teamSize > subTable.capacity
                  ? subTable.capacity : teamSize);
              teamSize -= subTable.capacity;
            }
            if (teamSize === 1 && foldingChairs > 0) {
              tablesOfTwo[tablesOfTwo.length - 1].currentSize += 1;
              foldingChairs -= 1;
              totalPeople += 1;
            }
          } else if (team.size > 3 && team.size < 6) {
            let tableOfTwo = currentTables.find((table) => (table.capacity === 2 && table.currentSize === 0));

            if (tableOfTwo && foldingChairs >= 2) {
              assignTable(tableOfTwo, team, printBookings, team.size);
            } else {
              let tableOfEight = currentTables.find((table) => (table.capacity === 8 && table.currentSize <= 2));

              if (tableOfEight) {
                assignTable(tableOfEight, team, printBookings, team.size)
              }
            }
          } else if (team.size === 6 && foldingChairs > 1) {
            let tablesOfTwo = findXTablesOfX(currentTables, 2, 2);

            teamSize = team.size;
            for (let subTable of tablesOfTwo) {
              assignTable(subTable, team, printBookings,
                3);
              teamSize -= 3;
            }
          } else if (team.size === 9 && foldingChairs >= 3) {
            let tablesOfTwo = findXTablesOfX(currentTables, 2, 3);

            teamSize = team.size;
            for (let subTable of tablesOfTwo) {
              assignTable(subTable, team, printBookings,
                  3);
              teamSize -= 3;
            }
          }
        } else if (team.size > 8) {
          // Otherwise, just try and split across whatever tables are left
          let sortedLargestTables = currentTables.sort((b, a) => a.capacity - b.capacity);

          if (currentTables.filter((table) => (table.currentSize > 0 && table.id !== 8 && table.id !== 9)).map((table) => table.capacity).reduce((a, b) => a + b, 0) > team.size) {
            // Not including both window tables
            sortedLargestTables = sortedLargestTables.filter((table) => (table.currentSize === 0) && table.id !== 8 && table.id !== 9);
          } else if (currentTables.filter((table) => (table.currentSize > 0 && table.id !== 8)).map((table) => table.capacity).reduce((a, b) => a + b, 0) > team.size) {
            // Not including right window table
            sortedLargestTables = sortedLargestTables.filter((table) => (table.currentSize === 0) && table.id !== 8);
          } else if (currentTables.filter((table) => (table.currentSize > 0)).map((table) => table.capacity).reduce((a, b) => a + b, 0) > team.size) {
            sortedLargestTables = sortedLargestTables.filter((table) => (table.currentSize === 0));
          }

          if (sortedLargestTables.length > 0) {
            let totalTeamSize = 0;
            let teamSize = team.size;
            for (let subTable of sortedLargestTables) {
              let amountToAssign =
                teamSize > subTable.capacity && teamSize > 12
                ? subTable.capacity :
                teamSize > subTable.capacity && subTable.capacity >= 6 && teamSize >= 6
                  ? 6 : teamSize > subTable.capacity
                    ? subTable.capacity : teamSize; // The current amount of people assigned to tables

              if ((amountToAssign > subTable.capacity && foldingChairs <= 0) || (amountToAssign > subTable.capacity && subTable.capacity === 3) || ((subTable.capacity - amountToAssign) > 2) && subTable !== sortedLargestTables[sortedLargestTables.length - 1]) {
                continue;
              }
              teamSize -= amountToAssign;
              totalTeamSize += amountToAssign;

              assignTable(subTable, team, printBookings,
                amountToAssign);

              // If all people have already been assigned
              if (totalTeamSize >= team.size || teamSize <= 0) {
                break;
              }
            }
          }
        }
      }
    }
  }

  // Check if no team was seated
  cannotBeSeated = checkAllTeamsContained(teams, currentTables)
  emptyTables = currentTables.filter(table => table.teams.length === 0);
  let emptySpaces = currentTables.reduce((sum, table) => table.currentSize === 0 ? sum + table.capacity : sum, 0); // Empty spaces from tables that don't have any teams on them

  return {assignedTables: currentTables, printBookings, cannotBeSeated: cannotBeSeated, emptySpaces, noTables: emptyTables, foldingChairs: Math.abs(foldingChairs - 5), total: totalPeople}
}