// Stylesheets
import './quizBookingSuccess.css';
import './quizBookingSuccessMobile.css';

// Images
import {QuizSuccess} from "../../../imgs";
import {handleAddToCalendar} from "../functions";

const QuizBookingSuccess = (props) => {
  let {translate, setOpen, bookingInfo, setBookingInfo, calendarDownload, bookingId, setBookingId, movePage} = props
  const displayText = () => {
    return <span> the <b>{bookingInfo.size.reduce((a, b) => a + b, 0)}</b> of</span>
  };

  return (
    <div style={{translate: translate}} className={'quizBookingSection quizBookingConfirmContainer'}>
      <div className={'bookingDetailsInnerContainer'}>
        <h1>WOOHOO!</h1>
        {bookingInfo !== null ? <>
          <div className={'bookingDetailsMessage'}>
            <div className={'message'}>
              Thanks for booking the Mosaic Tap Quiz, a booking confirmation will be sent
              to <b>{bookingInfo.email}</b> shortly.
            </div>
            <div className={'message'}>
              <i>Please allow up to 15 minutes for it to arrive, also remember to
                check your spam folder.</i>
            </div>
            <div className={'message'}>
              See{bookingInfo.size > 1 && displayText()} you on <b>{bookingInfo.date.split(' [SOLD OUT]')[0].replace('Wed', 'Wednesday')}</b> @ <b>{bookingInfo.time}</b>!
            </div>
            <br/>
            <img className={'quizBottomImg quizSuccessImg'} src={QuizSuccess} alt={'Illustration of some drinks.'}></img>
            <h2>Booking Information</h2>
            <h3>Add To Calendar</h3>
            <div>
              Make sure you don't miss out with a curated calendar event, will all the information you need!
            </div>
            <div className={'calendarBtnContainer'}>
              <button className={'adminBtn quizBtn calendarBtn googleCal'} onClick={() => handleAddToCalendar(bookingInfo, bookingId, 'google')}>
                Google
              </button>
              <button className={'adminBtn quizBtn calendarBtn iCal'} onClick={() => window.open(calendarDownload, '_blank')}>
                Apple
              </button>
              <button className={'adminBtn quizBtn calendarBtn otherCal'} onClick={() => window.open(calendarDownload, '_blank')}>
                Other
              </button>
            </div>
            <h3>Change Booking</h3>
            <div>
              Want to make a change or view your booking? Click the button below to view:
              <a href={`https://mosaictap.com/booking/${bookingId}/${bookingInfo.email}`} target={"_blank"} rel="noreferrer">
                <button className={'adminBtn quizBtn'}>
                  Booking Details
                </button>
              </a>
            </div>
            <h3>Book Again?</h3>
            <div className={'message'}>
              Want to book again? Remember you can change your current booking to have more than one team!
            </div>
            <div className={'message'}>
              If you still want to book again <i>(perhaps for next week)</i>, click below:
              <button className={'adminBtn quizBtn'} onClick={() => {
                document.cookie = 'quizBooking=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
                setBookingInfo(null);
                setBookingId(null);
                movePage(1);
              }}>
                Restart Booking
              </button>
            </div>
          </div>
        </> : <></>}
      </div>
      <br/>
      <div className={'quizConfirmBottomContainer'}>
        <div className={'quizConfirmBtnContainer'}>
          <button className={'adminBtn quizBtn'} onClick={() => {
            setOpen(false);
          }}>DISMISS
          </button>
        </div>
      </div>
    </div>
  )
}

export default QuizBookingSuccess