import './table.css';
import {useEffect, useState} from "react";
import {hover} from "@testing-library/user-event/dist/hover";

const Table = (props) => {
  const {table, x, y, width, height, tableSwap, setTableSwap, verbose} = props
  let [selected, setSelected] = useState(false);
  let [hovering, setHovering] = useState(false);

  useEffect(() => {
    if (selected) {
      setSelected(false);
    }
  }, [table]);

  return (
    <foreignObject
        className={'tableInfoContainer'}
        x={x} y={y} width={width} height={height}
        style={{
          backgroundColor: selected ? '#3fd53f' : table.teams.length > 0 ? 'rgba(213,213,213,0.15)' : 'rgba(255,255,255,0)'
        }}
        onMouseEnter={() => {setHovering(true)}} onMouseLeave={() => {setHovering(false)}}
    >
      <div className={'tableInfo'} style={{justifyContent: hovering || verbose ? 'space-between' : 'center'}}>
        <div
            className={'teamInfo'}
            style={{
              fontSize: hovering || verbose ? '10px' : '14px',
              // marginBottom: hovering || verbose ? '10px' : '0px'
        }}>
          {table.teams.length > 0 ? table.teams.map((team, index) => (
            <div key={`${team.name}-${index}`} className={'teamInfoItem noselect'}>
              {`${verbose || hovering ? team.name.split(' ')[0] : team.name} (${team.totalSize > 0 ? team.totalSize : team.size})`}<span> </span>
              {(hovering || verbose) && <button
                  style={{
                    fontSize: hovering || verbose ? '8px' : '0px'
                  }}
                  className={'swapTeamBtn'}
                  onClick={() => {
                    if (JSON.stringify(tableSwap) === JSON.stringify([{table: table, team}])) {
                      setTableSwap([])
                      setSelected(false)
                    } else if (tableSwap.length < 1) {
                      setTableSwap([{table: table, team}])
                      setSelected(true)
                    } else if (tableSwap.length === 1) {
                      setTableSwap([...tableSwap, {table: table, team}])
                      setSelected(false)
                    }
                  }}
              >🔀</button>}
            </div>
          )) : <i style={{fontSize: '10px'}}>0/{table.capacity}</i>}
        </div>
        <span
            className={'tableIdInfo noselect'}
            style={{
              // marginBottom: hovering || verbose ? `-${height - 20}px` : `-${height + 25}px`,
              fontSize: hovering || verbose ? `10px` : `0px`
            }}
        >
          <b>ID:</b> {table.id} (<i>{table.currentSize}/{table.capacity}</i>)<span> </span>
          {(hovering || verbose) && <button className={'swapTeamBtn'} onClick={() => {
            if (tableSwap.length < 1) {
              alert("Select a Team to move here first")
            } else if (tableSwap.length === 1) {
              setTableSwap([...tableSwap, table.id])
            }
          }}>+</button>}
        </span>
      </div>
    </foreignObject>
  )
}

export default Table;