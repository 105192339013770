export const handleAddToCalendar = (bookingInfo, bookingId, calendarType) => {
  let calendarUrl;
  let title = 'Mosaic Tap Quiz';
  let location = 'Mosaic Tap, Arch 7, Forth Goods Yard, NE1 3PG';
  let formattedStartTime = formatDateTime(bookingInfo.date, bookingInfo.time);
  let formattedEndTime = formatDateTime(bookingInfo.date, '10:30pm');
  let description = `Thanks for booking the Mosaic Tap Quiz! You have booked for <b>${bookingInfo.size}</b> on <b>${bookingInfo.date.split(' [SOLD OUT]')[0].replace('Wed', 'Wednesday')}</b> @ <b>${bookingInfo.time}</b>.<br/><br/>If you would like to update your booking, go to: https://mosaictap.com/booking/${bookingId}/${bookingInfo.email}`

  if (calendarType === 'google') {
    // Google Calendar URL
    calendarUrl = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(title)}&dates=${formattedStartTime}/${formattedEndTime}&details=${encodeURIComponent(description)}&location=${encodeURIComponent(location)}`;
  } else if (calendarType === 'apple') {
    // Apple Calendar URL (webcal:// link)
    const icsData = `BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
DTSTART:${formattedStartTime}
DTEND:${formattedEndTime}
SUMMARY:${title}
DESCRIPTION:${description}
LOCATION:${location}
END:VEVENT
END:VCALENDAR`;
    calendarUrl = `webcal://${window.location.host}/event.ics?data=${encodeURIComponent(icsData)}`;
  } else {
    return; // Invalid calendar type
  }

  window.open(calendarUrl, '_blank');
};

function formatLocalDateManual(year, month, day) {
  const date = new Date(year, month - 1, day);
  const yearStr = date.getFullYear();
  const monthStr = String(date.getMonth() + 1).padStart(2, '0');
  const dayStr = String(date.getDate()).padStart(2, '0');
  return `${yearStr}-${monthStr}-${dayStr}`;
}

export const formatDateTime = (dateString, timeString) => {
  const [dayName, month, day, year] = dateString.split(' ');

  const formattedDate = new Date(`${month}, ${day}, ${year}`).toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' });

  const timeRegex = /(\d+):(\d+)([ap]m)/i;
  const timeMatch = timeString.match(timeRegex);
  if (timeMatch) {
    let hours = parseInt(timeMatch[1], 10);
    const minutes = parseInt(timeMatch[2], 10);
    const ampm = timeMatch[3].toLowerCase();
    if (ampm === 'pm' && hours !== 12) hours += 12;
    if (ampm === 'am' && hours === 12) hours = 0;
    const dateTime = new Date(formattedDate);
    dateTime.setHours(hours, minutes, 0, 0);
    console.log(dateTime.toISOString().replace(/-|:|\.\d+/g, ''))
    return dateTime.toISOString().replace(/-|:|\.\d+/g, '');
  } else {
    return; // Invalid time format
  }
};